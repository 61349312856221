import { Children } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import style from './ToggleMenu.module.css';

const ToggleMenu = ({ as: Component, className, children }) => (
  <Component
    className={cx(
      'flex rounded-xl p-1 bg-gray-200 gap-x-1',
      style[`items-${Children.toArray(children).length}`],
      className
    )}
  >
    {children}
  </Component>
);

ToggleMenu.propTypes = {
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.shape({ render: PropTypes.func }),
  ]),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

ToggleMenu.defaultProps = {
  as: 'nav',
  className: '',
};

export default ToggleMenu;
